<template>
  <section id="home">
    <b-row>
      <b-col xl="8">
        <b-card no-body>
          <b-card-header>
            <h4 class="mb-0">
              Calendar
              <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-calendar" />
            </h4>
            <b-popover target="popover-calendar" triggers="hover" placement="bottom">
              <span>Google Calendar for all team events, including vacations, sick days, meetings, and more. Keep
                track of important dates and stay organized effortlessly.</span>
            </b-popover>
          </b-card-header>
          <iframe
            src="https://calendar.google.com/calendar/u/0/embed?src=1mib0j64q7gm9arq4s47id393g@group.calendar.google.com&ctz=Europe/Berlin"
            style="border: 0" width="100%" height="800" frameborder="0" scrolling="no" />
        </b-card>
      </b-col>
      <b-col xl="4">
        <b-card no-body>
          <b-card-header>
            <h4 class="mb-0">
              Useful links
              <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-useful-links" />
            </h4>
            <b-popover target="popover-useful-links" triggers="hover" placement="bottom">
              <span>The Useful Links section contains a collection of helpful resources and tools to assist with your
                work. Access key websites and applications quickly and easily.</span>
            </b-popover>
          </b-card-header>
          <b-card-body>
            <b-badge
              href="https://docs.google.com/document/d/1H-qlBlaQiLAeFiMdd4CFt08LKezR8K32F9_CebWMT_E/edit?usp=share_link"
              target="_blank" variant="primary">
              <feather-icon icon="LinkIcon" class="mr-25" />
              <span>How to JTL</span>
            </b-badge>&nbsp;
            <b-badge href="https://docs.google.com/document/d/1RqDy3_1XL1E9chWMjKyR-Y754G7QULrg0-8X4b45-pM/edit"
              target="_blank" variant="primary">
              <feather-icon icon="LinkIcon" class="mr-25" />
              <span>Fragenkatalog</span>
            </b-badge>&nbsp;
            <b-badge href="https://classroom.google.com/u/1/c/NTM1ODI0NzQxOTQy" target="_blank" variant="primary">
              <feather-icon icon="LinkIcon" class="mr-25" />
              <span>Google Classroom</span>
            </b-badge>&nbsp;
            <b-badge
              href="https://drive.google.com/drive/folders/0B_yUq1bDxnIcVEhsN2tDU0p0NW8?resourcekey=0-et2CJYAobKgYrZXALaIoRA&usp=sharing"
              target="_blank" variant="primary">
              <feather-icon icon="LinkIcon" class="mr-25" />
              <span>Googlle Drive</span>
            </b-badge>&nbsp;
            <b-badge href="https://calendly.com/hendirk" target="_blank" variant="primary">
              <feather-icon icon="LinkIcon" class="mr-25" />
              <span>Book a call with Hendirk</span>
            </b-badge>
          </b-card-body>
        </b-card>
        <b-overlay :show="showMyHoursWorked" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
          <b-card v-if="myHoursWorked" no-body>
            <b-card-header>
              <h4 class="mb-0">
                Working hours
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-working-hours" />
              </h4>
              <b-popover target="popover-working-hours" triggers="hover" placement="bottom">
                <span>Total number of hours you have worked this month, helping you keep track of your time and
                  productivity.</span>
              </b-popover>
            </b-card-header>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ myHoursWorked }}
                </h2>
                <span>Total traqq hours for this month</span>
              </div>
              <b-avatar variant="light-secondary" size="45">
                <feather-icon size="21" icon="ClockIcon" />
              </b-avatar>
            </b-card-body>
          </b-card>
        </b-overlay>
        <b-overlay :show="showTasks" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                TO-DO list
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-todo-list" />
              </h4>
              <b-popover target="popover-todo-list" triggers="hover" placement="bottom">
                <span>The TO-DO List section shows all team tasks with their respective dates and assigned members.
                  You can also create new tasks by clicking the 'Add Task' button, which opens a modal with input
                  forms for task details.</span>
              </b-popover>
            </b-card-header>
            <b-list-group>
              <b-list-group-item v-for="(task, index) in tasks" :key="index" class="d-flex justify-content-between"
                @click.self="updateTaskRowClicked(task)">
                <span>
                  <b-form-checkbox v-model="task.is_done" @change="handleCheckboxChange(task.id, task.is_done)">
                    <h4>{{ task.title }}
                      <small class="text-secondary">{{ task.date }}</small>
                    </h4>
                  </b-form-checkbox>
                  <b-badge v-for="user in task.assigned_to_names" :key="user.id" variant="light-primary">
                    {{ user.username }}
                  </b-badge>
                </span>
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="flat" class="btn-icon"
                  @click="openDeleteTaskConfirm(task.id)">
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </b-list-group-item>
            </b-list-group>
            <br>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-block" @click="addTask">
              Add task
            </b-button>
          </b-card>
        </b-overlay>
        <b-modal ref="modal-task-submit" cancel-variant="outline-secondary" ok-title="Submit" cancel-title="Close"
          centered :title="taskID ? 'Update Task' : 'Add Task'" :no-close-on-backdrop="true" @ok="handleTaskFormSubmit"
          @hidden="resetTaskForm">
          <b-form>
            <b-form-group label="Title" label-for="taksTitle">
              <b-form-input id="taksTitle" v-model="taksTitle" />
            </b-form-group>
            <b-form-group label="Description" label-for="taksDescription">
              <b-form-textarea id="taksDescription" v-model="taksDescription" rows="3" />
            </b-form-group>
            <b-form-group label="Date" label-for="taksDate">
              <flat-pickr v-model="taksDate" class="form-control" />
            </b-form-group>
            <b-form-group label="Assigned to" label-for="assigned-to">
              <v-select id="assigned-to" v-model="assignedToSelected" label="username" multiple :options="assignedTo" />
            </b-form-group>
          </b-form>
        </b-modal>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BOverlay,
  BAvatar,
  BBadge,
  BButton,
  BFormCheckbox,
  BListGroup,
  BListGroupItem,
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BPopover,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive';
import flatPickr from 'vue-flatpickr-component';
import axios from 'axios';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BOverlay,
    BAvatar,
    BBadge,
    BButton,
    BFormCheckbox,
    BListGroup,
    BListGroupItem,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BPopover,
    vSelect,
    flatPickr,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showTasks: true,
      showMyHoursWorked: true,
      myHoursWorked: '',
      tasks: [],
      taskID: '',
      taksTitle: '',
      taksDescription: '',
      taksDate: '',
      assignedTo: [],
      assignedToSelected: [],
    }
  },
  async created() {
    try {
      await this.getTasks();
      await this.getMyHoursWorked();
      await this.getUsers();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  methods: {
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: text,
          icon: 'BellIcon',
          variant,
        },
      })
    },
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getTasks() {
      this.showTasks = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/tasks/`, {});
        this.tasks = response.data.results;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showTasks = false;
      }
    },
    async getMyHoursWorked() {
      this.showMyHoursWorked = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/my-hours-worked/`, {});
        if (response.data.results.length > 0) {
          const minutesWorked = response.data.results[0].minutes_worked;
          const hours = Math.floor(minutesWorked / 60);
          const minutes = minutesWorked % 60;
          this.myHoursWorked = `${hours}:${minutes.toString().padStart(2, '0')}`;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showMyHoursWorked = false;
      }
    },
    async getUsers() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/all-users/`, {});
        this.assignedTo = response.data;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    addTask() {
      this.$refs['modal-task-submit'].show();
    },
    updateTaskRowClicked(task) {
      this.taskID = task.id;
      this.taksTitle = task.title;
      this.taksDescription = task.description;
      this.taksDate = task.date;
      this.assignedToSelected = task.assigned_to_names;
      this.$refs['modal-task-submit'].show();
    },
    async handleCheckboxChange(id, value) {
      await axios
        .patch(`${process.env.VUE_APP_ROOT_API}/tasks/${id}/`, { is_done: value }, {
          headers: {
            Authorization: `JWT ${this.$store.state.jwt}`,
            'Content-Type': 'application/json',
          },
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.$router.push({ name: 'login' });
          }
        })
    },
    async handleTaskFormSubmit() {
      const params = {
        user: localStorage.getItem('useridLocal'),
        title: this.taksTitle,
        description: this.taksDescription,
        date: this.taksDate,
        assigned_to: this.assignedToSelected.map(item => item.id),
      };

      if (this.taskID) {
        await axios
          .put(`${process.env.VUE_APP_ROOT_API}/tasks/${this.taskID}/`, params, {
            headers: {
              Authorization: `JWT ${this.$store.state.jwt}`,
              'Content-Type': 'application/json',
            },
          })
          .then(async response => {
            console.log(response);
            this.showToast('success', 'Task has been updated');
            await this.getTasks();
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.$router.push({ name: 'login' });
            }
          })
      } else if (this.taksTitle !== '' && this.taksDate !== '') {
        await axios
          .post(`${process.env.VUE_APP_ROOT_API}/tasks/`, params, {
            headers: {
              Authorization: `JWT ${this.$store.state.jwt}`,
              'Content-Type': 'application/json',
            },
          })
          .then(async response => {
            console.log(response);
            this.showToast('success', 'Task has been created');
            await this.getTasks();
          })
          .catch(error => {
            if (error.response.status === 401) {
              this.$router.push({ name: 'login' });
            }
          })
      } else { this.showToast('danger', 'Error!'); }
    },
    resetTaskForm() {
      this.taskID = '';
      this.taksTitle = '';
      this.taksDescription = '';
      this.taksDate = '';
      this.assignedToSelected = [];
    },
    async openDeleteTaskConfirm(id) {
      this.taskID = id;
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          try {
            await axios
              .delete(`${process.env.VUE_APP_ROOT_API}/tasks/${this.taskID}/`, {
                headers: {
                  Authorization: `JWT ${this.$store.state.jwt}`,
                  'Content-Type': 'application/json',
                },
              })
            this.showToast('info', 'Task has been deleted');
            await this.getTasks();
          } catch (error) {
            if (error.response.status === 401) {
              this.$router.push({ name: 'login' });
            }
          }
        }
        this.taskID = '';
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
